import { push } from 'connected-react-router'
import { useSlideQuery } from 'entities/slide'
import { notices } from 'features/notices'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { selectUrlCaseId, selectUrlSlideId } from 'pages/viewer'
import {
  ISlideState,
  IViewingState,
  useOpenViewers,
  useViewerIdSlideState,
  useViewerPageProvided,
} from 'pages/viewer/lib/common/ViewerPageProvider'
import { FC, HTMLProps, memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { ORIGIN_CASE_DATA_KEY, ORIGIN_CASES_DATA_KEY } from 'shared/lib/common/storageKeys'
import { formatDateForDisplay } from 'shared/lib/date'
import { addToLocalStorage, findCaseBySlideId, getFromLocalStorage, saveCasesData } from 'shared/lib/local-storage'
import CaseLinkIcon from 'shared/ui/icons/CaseLinkIcon'
import styled from 'styled-components'
import { ECaseLinkType, ICaseUser, TCaseLinkDTO } from 'types/ICase'
import IReport from 'types/IReport'
import { IGroupType } from 'types/ISlide'
import TViewerId from 'types/TViewerId'

type Props = {
  caseRecords: TCaseLinkDTO[]
}

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const StyledListItem = styled.div<{ active: boolean }>`
  padding: 8px;
  width: 100%;
  color: ${({ active }) => (active ? 'var(--color-white)' : 'var(--color-text-1)')};
  font-weight: 500;
  line-height: 16px;
  background: ${({ active }) => active && 'var(--color-purple)'};
  border-radius: ${({ active }) => active && '5px'};
  &:hover {
    background: ${({ active }) => (active ? 'var(--color-purple)' : 'var(--color-bg-3)')};
    border-radius: 5px;
  }
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0px;
  }
`

const StyledItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2px;
  white-space: nowrap;
`

const StyledItem = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
`

const StyledContent = styled(StyledItem)<{ active: boolean }>`
  color: ${({ active }) => (active ? 'var(--color-white)' : 'var(--color-text-3)')};
  margin-top: 4px;
`

const StyledName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`

const findKeysNotEqualToCaseId = (obj: IViewingState, caseId: number) =>
  Object.keys(obj).filter((key) => obj[key as keyof IViewingState]?.slide.caseId !== caseId)

export type OriginCaseData = {
  /** Путь текущего маршрута */
  path: string
  /** Идентификатор слайда, который был активен. */
  slideId?: number
  /** Идентификатор случая, который был активен. */
  caseId: number
  /** Тип группы, к которой относится текущий слайд. */
  groupType: IGroupType
}

type ItemProps = {
  caseRecord: TCaseLinkDTO
  active: boolean
}

const CaseItem: FC<ItemProps> = memo(({ active, caseRecord }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { changeViewerSlide, removeViewer, setAutoCoreg, setHandableCoreg, viewingState } = useViewerPageProvided()
  const { activeViewerId, openViewerIds } = useOpenViewers()
  const { slideId, source } = useViewerIdSlideState(activeViewerId)
  const currentWorkspaceId = useCurrentWorkspaceId()
  const { caseDate, caseId, createdAt, incomingIcd10, linkType, name, reports, user, workspace } = caseRecord
  const urlCaseId = useSelector(selectUrlCaseId) || caseId
  const urlSlideId = useSelector(selectUrlSlideId)
  const { data: slide } = useSlideQuery({ slideId, source })

  useEffect(() => {
    if (urlCaseId) {
      const viewersToRemove = findKeysNotEqualToCaseId(viewingState, urlCaseId)
      viewersToRemove.forEach((item) => removeViewer(item as TViewerId))
    }
  }, [viewingState, urlCaseId])

  // Вспомогательная функция для смены слайда
  const changeSlide = (caseData?: OriginCaseData) => {
    const { caseId: localCaseId = urlCaseId, groupType = 'MICRO', path, slideId = urlSlideId } = caseData || {}
    const viewerSlide: ISlideState = {
      caseId: localCaseId,
      slideGroupType: groupType,
      slideId: slideId as number,
      source: source || 'PLATFORM',
      viewerMode: 'DEFAULT',
    }

    dispatch(push(path || location.pathname))
    changeViewerSlide(activeViewerId, viewerSlide)
  }

  const resetCoreg = () => {
    setAutoCoreg(false)
    setHandableCoreg(false)
  }

  const onReturnToOriginCase = () => {
    const localOriginCaseData = getFromLocalStorage<OriginCaseData>(ORIGIN_CASE_DATA_KEY)
    if (localOriginCaseData) {
      resetCoreg()
      changeSlide(localOriginCaseData)
      localStorage.removeItem(ORIGIN_CASES_DATA_KEY)
      localStorage.removeItem(ORIGIN_CASE_DATA_KEY)
    }
  }

  const onClick: HTMLProps<HTMLDivElement>['onClick'] = (e) => {
    e.stopPropagation()
    if (active) return
    const localOriginCaseData = getFromLocalStorage<OriginCaseData>(ORIGIN_CASE_DATA_KEY)
    const localOriginCasesData = getFromLocalStorage(ORIGIN_CASES_DATA_KEY)

    // Получение данных из localStorage
    const { caseId: localCaseId = urlCaseId } = localOriginCaseData || {}
    const path = openViewerIds.length > 1 ? location.pathname : `${location.pathname}${location.search}`

    // Формирование данных для текущего кейса
    const originCaseData = {
      caseId: urlCaseId,
      groupType: slide?.groupType,
      path,
      slideId: urlSlideId,
    }
    // Сохранение данных о кейсе, если их нет
    if (!localOriginCaseData) {
      addToLocalStorage(ORIGIN_CASE_DATA_KEY, originCaseData)
    }

    // Логика возврата на исходный кейс
    if (localOriginCaseData && path && localCaseId === caseId) {
      resetCoreg()
      saveCasesData(urlCaseId, slide?.groupType, path, urlSlideId)
      return changeSlide(localOriginCaseData)
    }

    // Логика для смены кейса
    const currentCaseData = findCaseBySlideId(caseId)
    if (localCaseId !== caseId && localOriginCasesData && caseId === currentCaseData?.caseId) {
      saveCasesData(urlCaseId, currentCaseData.groupType, path, urlSlideId)
      return changeSlide(currentCaseData)
    }

    // Логика для workspace
    if (workspace) {
      resetCoreg()
      saveCasesData(urlCaseId, slide?.groupType, path, undefined)
      dispatch(push(`/workspace/${currentWorkspaceId}/viewer/${caseId}`))
      notices.openOnOtherPatientCaseNotification({ key: 'otherCase', onReturnToOriginCase })
    }
  }
  /** Если в заключении несколько значений, то возвращает более старое по полю created, которое написал владелец случая */
  const filterReportsByUserId = (reports: IReport[], user: ICaseUser) => {
    const userId = user?.userId

    if (reports?.length === 1) return reports[0]

    const filteredReports = reports
      .filter((report) => report?.createdBy?.userId === userId)
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())

    const oldestReport = reports.reduce((oldest, current) =>
      new Date(oldest.createdAt) < new Date(current.createdAt) ? oldest : current,
    )

    return filteredReports[0] ? filteredReports[0] : oldestReport
  }

  /** Возвращает элементы с информацией Icd10 и IcdO */
  const renderReports = () => {
    const filteredReport = reports && filterReportsByUserId(reports, user)
    const isSignedReports = filteredReport?.signed === true || filteredReport?.signed === null

    if (incomingIcd10 && !isSignedReports) {
      return <StyledContent active={active}>{`${incomingIcd10?.shortName}: ${incomingIcd10?.name};`}</StyledContent>
    }

    if (filteredReport && isSignedReports) {
      if (filteredReport?.icd10DetailedList) {
        return filteredReport?.icd10DetailedList?.map((item) => (
          <StyledContent active={active} key={item?.icd10?.id}>
            {`${item?.icd10?.shortName}: ${item?.icd10?.name}`}
            <br />
            {item?.morphologicalIcdO && `(${item?.morphologicalIcdO.shortName});`}
          </StyledContent>
        ))
      }

      if (filteredReport.icd10) {
        return (
          <StyledContent active={active}>
            {`${filteredReport?.icd10?.shortName}: ${filteredReport?.icd10?.name}`}
            <br />
            {filteredReport?.morphologyCode && `(${filteredReport?.morphologyCode?.shortName});`}
          </StyledContent>
        )
      }

      return null
    }

    if (incomingIcd10) {
      return <StyledContent active={active}>{`${incomingIcd10?.shortName}: ${incomingIcd10?.name};`}</StyledContent>
    }

    return null
  }

  /** caseId = null, из ЛИС пришла информация, что это parentCase, но его еще нет в БД */
  if (!caseId) {
    return (
      <StyledListItem
        active={false}
        style={{ cursor: 'not-allowed' }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <StyledItemContainer>
          {linkType && <CaseLinkIcon isParent={linkType === ECaseLinkType.PARENT} />}
          <StyledName>{name}</StyledName>
        </StyledItemContainer>
      </StyledListItem>
    )
  }

  return (
    <StyledListItem onClick={onClick} active={active}>
      <CaseItemContainer caseName={name} linkType={linkType} caseDate={caseDate || createdAt || ''} />
      {Number(workspace?.workspaceId) !== currentWorkspaceId && <StyledItem>{workspace?.name}</StyledItem>}
      {incomingIcd10 && !!reports.length && renderReports()}
    </StyledListItem>
  )
})

export const AllCasesContainer: FC<Props> = ({ caseRecords }) => {
  const { params } = useRouteMatch<{ caseId: string; workspaceId: string }>()
  return (
    <StyledList>
      {caseRecords.map((caseRecord) => (
        <CaseItem key={caseRecord?.caseId} caseRecord={caseRecord} active={caseRecord.caseId === +params.caseId} />
      ))}
    </StyledList>
  )
}

type TCaseItemProps = {
  /** Тип связи кейса */
  linkType: ECaseLinkType | null
  caseName: string
  caseDate: string
}

const CaseItemContainer: FC<TCaseItemProps> = ({ caseDate, caseName, linkType }) => (
  <StyledItemContainer>
    {linkType && <CaseLinkIcon isParent={linkType === ECaseLinkType.PARENT} />}
    <StyledName>{caseName}</StyledName>
    <StyledItem>{formatDateForDisplay(caseDate)}</StyledItem>
  </StyledItemContainer>
)
