import { useTypedSelector } from 'app/redux/lib/selector'
import { useUploadedFilesQuery } from 'features/uploaded-file/api/query'
import { ROTATION_INCREMENT, ViewType } from 'features/uploaded-file/lib/common'
import { useUploadedFileQueryParams } from 'features/uploaded-file/lib/hooks'
import ContextMenuUploadedFile from 'features/uploaded-file/ui/context-menu/ContextMenuUploadedFile'
import UploadedFilePreviewImage from 'features/uploaded-file/ui/preview-image/UploadedFilePreviewImage'
import { useUploadedFileContext } from 'features/uploaded-file/ui/table/UploadedFileContext'
import { useUploadedFileTabContext } from 'features/uploaded-file/ui/UploadedFileTabContext'
import React, { FC, useMemo, useRef, useState } from 'react'
import { InfiniteScroll } from 'shared/ui/infinite-scroll'
import styled from 'styled-components'
import { EUploadedFileTab } from 'types/IUploadedFile'
import { IUploadedFileDTO } from 'types/IUploadedFileDTO'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'

const DEFAULT_PAGE_SIZE = 20

type TProps = {
  /** Функция для удаления файла. */
  onDelete: (uploadedFileIds: number[], uploadedFileTab?: EUploadedFileTab) => void
}

const UploadedFileGrid: FC<TProps> = ({ onDelete }) => {
  const menuRef = useRef(null)
  const { tileSize } = useUploadedFileContext()
  const [rotateLabels, setRotateLabels] = useState<Record<number, number>>({})
  const [menuPosition, setMenuPosition] = useState<number[]>([])
  const [activeFile, setActiveFile] = useState<IUploadedFileDTO | null>(null)

  const isShowContextMenu = !!menuPosition.length

  const { selectedFiles } = useUploadedFileTabContext()

  const { currentTab } = useUploadedFileQueryParams()
  const filters = useTypedSelector((state) => state.viewerPage.sumpFilters[currentTab])

  const { fetchNextPage, hasNextPage, uploadedFileList } = useUploadedFilesQuery(currentTab, filters, DEFAULT_PAGE_SIZE)

  const fileIdsToDelete = useMemo(() => selectedFiles.map(({ uploadedFileId }) => uploadedFileId), [selectedFiles])

  const onCloseMenu = () => {
    setMenuPosition([])
    setActiveFile(null)
  }

  const handleContextMenu = (e: React.MouseEvent<HTMLDivElement>, file: IUploadedFileDTO) => {
    e.preventDefault()
    setMenuPosition([e.clientX, e.clientY])
    setActiveFile(file)
  }

  const handleRotateLabel = () => {
    if (activeFile) {
      setRotateLabels((prev) => ({
        ...prev,
        [activeFile.uploadedFileId]: (prev[activeFile.uploadedFileId] || 0) + ROTATION_INCREMENT,
      }))
      onCloseMenu()
    }
  }

  const handleRotateAllLabels = () => {
    setRotateLabels((prev) => {
      const updatedLabels = { ...prev }

      uploadedFileList.forEach((file) => {
        updatedLabels[file.uploadedFileId] = (updatedLabels[file.uploadedFileId] || 0) + ROTATION_INCREMENT
      })

      return updatedLabels
    })
  }

  const handleDeleteFile = () => {
    if (fileIdsToDelete.length) {
      onDelete(fileIdsToDelete)
    } else {
      activeFile && onDelete([activeFile.uploadedFileId], activeFile.uploadedFileTab)
    }
    onCloseMenu()
  }

  useOnClickOutside(menuRef, onCloseMenu, undefined, menuPosition)

  const fetchMore = async () => {
    if (hasNextPage) {
      await fetchNextPage()
    }
  }

  const isLabelUrl = uploadedFileList.some(
    (file) => file.uploadedFileId === activeFile?.uploadedFileId && file.labelUrl,
  )

  return (
    <InfiniteScroll
      fetchMore={fetchMore}
      isLastPage={!hasNextPage}
      itemsLength={uploadedFileList?.length || 0}
      style={{ overflowY: 'scroll', width: '100%' }}
    >
      {isShowContextMenu && (
        <ContextMenuUploadedFile
          menuRef={menuRef}
          isLabelUrl={isLabelUrl}
          onDelete={handleDeleteFile}
          handleRotateLabel={handleRotateLabel}
          handleRotateAllLabels={handleRotateAllLabels}
          menuPosition={menuPosition}
        />
      )}
      <StyledWrapper width={tileSize}>
        {uploadedFileList.map((file) => (
          <UploadedFilePreviewImage
            key={file.uploadedFileId}
            handleContextMenu={(e) => handleContextMenu(e, file)}
            rotateLabel={rotateLabels[file.uploadedFileId] || 0}
            viewType={ViewType.GRID}
            file={file}
          />
        ))}
      </StyledWrapper>
    </InfiniteScroll>
  )
}

export default UploadedFileGrid

const StyledWrapper = styled.div<{ width: number }>`
  display: grid;
  grid-template-columns: ${({ width }) => `repeat(auto-fit, ${width}px)`};
  gap: 16px;
  padding: 16px;
  width: 100%;
  height: fit-content;
  justify-content: start;
  border-top: 1px solid var(--color-border-1);
`
